import type {
  TCreateCompanyManagerRequest,
  TListCompanyManagerQuery,
  TListCompanyManagerResponse
} from '@tl-lms/types/company-managers.d.ts'
import type { TNAPIBody, TNAPIQuery } from '~/utils/napi-helpers'

function listCompanyManager(
  query: TListCompanyManagerQuery | Ref<TListCompanyManagerQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
) {
  const { data: user } = useAuth()
  const { fetchOnce } = useAuthFetch()
  if (!user.value) return null

  async function fetchHandler() {
    const _query = removeFalsyValuesFromPayload(query, ['*'])
    return await fetchOnce<TListCompanyManagerResponse>(
      `/api/company-managing/`,
      'GET',
      undefined,
      _query,
      oFetchOptions
    )
  }

  return useAsyncData<TListCompanyManagerResponse>(
    `company-managers-list-${JSON.stringify(query)}`,
    fetchHandler,
    {
      // only watch if query is a ref
      watch: isRef(query) || isReactive(query) ? [query] : undefined,
      ...asyncDataOptions
    }
  )
}

function createCompanyManager(payload: TCreateCompanyManagerRequest) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce(`/api/company-managing/`, 'POST', payload)
}

function deleteCompanyManager(idSlug: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce(`/api/company-managing/${idSlug}/`, 'DELETE')
}

function getStaffEmailNotification(
  query: TNAPIQuery<'/napis/v1/jobs/company_staff_email_notification/', 'get'>,

  asyncDataOptions: Record<string, any> = {}
) {
  const { openApiReactiveList } = useAuthFetch()
  return openApiReactiveList(
    '/napis/v1/jobs/company_staff_email_notification/',
    undefined,
    query,
    asyncDataOptions
  )
}

function updateStaffEmailNotification(
  payload: TNAPIBody<'/napis/v1/jobs/company_staff_email_notification/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce(
    '/napis/v1/jobs/company_staff_email_notification/',
    'post',
    undefined,
    undefined,
    payload
  )
}

type UseCompanyManagerReturnType = {
  createCompanyManager: typeof createCompanyManager
  listCompanyManager: typeof listCompanyManager
  deleteCompanyManager: typeof deleteCompanyManager
  updateStaffEmailNotification: typeof updateStaffEmailNotification
  getStaffEmailNotification: typeof getStaffEmailNotification
}

export function useCompanyManager(): UseCompanyManagerReturnType {
  return {
    createCompanyManager,
    listCompanyManager,
    deleteCompanyManager,
    updateStaffEmailNotification,
    getStaffEmailNotification
  }
}
